<template>
  <!-- Main modal -->
  <div
    v-if="visible"
    class="fixed top-4 right-6 left-0 z-50 flex justify-center items-center w-full h-full bg-gray-800 bg-opacity-50"
  >
    <div class="relative p-4 w-full max-w-4xl mx-auto">

      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow-lg overflow-y-auto max-h-full">

        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 border-b rounded-t">
          <h3 class="text-xl font-semibold text-red-600">
            {{ isConference ? "Create a Conference" : "Create a Payment" }}
          </h3>
          <button
            @click="$emit('close')"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
          >
            X
            <span class="sr-only">Close modal</span>
          </button>
        </div>

        <!-- Modal body -->
        <div class="p-4 md:p-5">
          <div class="col-span-2">

            <!-- Common input fields -->
            <div class="mb-4">
              <label for="name" class="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                id="name"
                v-model="name"
                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 bg-gray-50 sm:text-sm"
                :placeholder="isConference ? 'Enter conference name' : 'Enter payment name'"
              />
            </div>

            <!-- Conditional fields for Conference -->
            <template v-if="isConference">

              <!-- Duration for Conference -->
              <div class="mb-4">
                <label for="duration" class="block text-sm font-medium text-gray-700">
                  Duration
                </label>
                <div class="pt-2 flex">
                  <flat-pickr
                    v-model="startDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                    placeholder="Start date and time"
                    :config="conferenceConfig"
                    @change="handleDateChange"
                  ></flat-pickr>
                  <span class="mx-4 text-gray-500">to</span>
                  <flat-pickr
                    v-model="endDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                    placeholder="End date and time"
                    :config="conferenceConfig"
                    @change="handleDateChange"
                  ></flat-pickr>
                </div>
              </div>

              <!-- Conference-specific fields -->
              <div class="mb-4">
                <label for="venue" class="block text-sm font-medium text-gray-700">
                  Venue
                </label>
                <input
                  type="text"
                  id="venue"
                  v-model="venue"
                  class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 bg-gray-50 sm:text-sm"
                  placeholder="Enter conference venue"
                />
              </div>

              <!-- Check-In and Payment options for Conference -->
              <div class="mb-4 flex space-x-4">
                <div>
                  <label for="checkin" class="block text-sm font-medium text-gray-700">
                    Enable Check-In
                  </label>
                  <input
                    type="checkbox"
                    id="checkin"
                    v-model="enableConferenceCheckin"
                    class="mt-1"
                  />
                </div>
                <div>
                  <label for="payment" class="block text-sm font-medium text-gray-700">
                    Enable Payment
                  </label>
                  <input
                    type="checkbox"
                    id="payment"
                    v-model="enablePayment"
                    class="mt-1"
                  />
                </div>
              </div>

              <!-- Check-In Days selection for Conference using Vue Multiselect -->
              <template v-if="enableConferenceCheckin">
                <div class="mb-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Select Check-In Days
                  </label>
                  <multiselect
                    v-model="selectedConferenceCheckinDays"
                    :options="checkinDays"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select check-in days"
                    :select-label="'Click to select'"
                    class="mt-1 block w-full"
                  />
                </div>
              </template>
            </template>

            <!-- Conditional field for Payment -->
            <template v-if="!isConference">
              
              <!-- Duration for Payment -->
              <div class="mb-4">
                <label for="duration" class="block text-sm font-medium text-gray-700">
                  Duration
                </label>
                <div class="pt-2 flex">
                  <flat-pickr
                    v-model="paymentStartDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                    placeholder="Start date"
                    :config="paymentConfig"
                    @change="handleDateChange"
                  ></flat-pickr>
                  <span class="mx-4 text-gray-500">to</span>
                  <flat-pickr
                    v-model="paymentEndDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                    placeholder="End date"
                    :config="paymentConfig"
                    @change="handleDateChange"
                  ></flat-pickr>
                </div>
              </div>

              <!-- Check-In option for Payment -->
              <div class="mb-4 pt-2">
                <label for="checkin" class="block text-sm font-medium text-gray-700">
                  Enable Check-In
                </label>
                <input
                  type="checkbox"
                  id="checkin"
                  v-model="enablePaymentCheckin"
                  class="mt-1"
                />
              </div>

              <!-- Check-In Days selection for Payment using Vue Multiselect -->
              <template v-if="enablePaymentCheckin">
                <div class="mb-4">
                  <label class="block text-sm font-medium text-gray-700">
                    Select Check-In Days
                  </label>
                  <multiselect
                    v-model="selectedPaymentCheckinDays"
                    :options="checkinDays"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select check-in days"
                    class="mt-1 block w-full focus:ring-red-500 focus:border-red-500"
                  />
                </div>
              </template>
            </template>
          </div>
        </div>

        <!-- Modal footer -->
        <div
          class="sticky bottom-0 left-0 right-0 bg-white border-t border-gray-200 rounded-b"
        >
          <div
            class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b"
          >
            <button
              @click="generateLink"
              :disabled="isGenerateLinkButtonDisabled"
              type="button"
              class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Generate Link
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Generated link modal -->
  <div
    v-if="linkModalOpen"
    class="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-gray-800 bg-opacity-50"
  >
    <div class="relative p-4 w-full max-w-md mx-auto">
      <div class="relative bg-white rounded-lg shadow-lg overflow-y-auto max-h-full">
        <div class="flex items-center justify-between p-4 border-b rounded-t">
          <h3 class="text-xl font-semibold text-red-600">Generated Link</h3>
          <button
            @click="toggleLinkModal"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
          >
            X
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="p-4 md:p-5">
          <p class="text-gray-700">Here's your generated link:</p>
          <p>
            <a :href="generatedLink" target="_blank">{{ generatedLink }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from '../config';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import { useNotificationStore } from "@/stores/notificationStore";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "modalComp",
  emits: ['close', 'conferenceCreated'],
  components: {
    flatPickr,
    Multiselect
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Modal state controlled by parent via the "visible" prop.
      linkModalOpen: false,
      isConference: true,
      name: "",
      venue: "",
      startDate: null,
      endDate: null,
      paymentStartDate: null,
      paymentEndDate: null,
      enableConferenceCheckin: false,
      enablePaymentCheckin: false,
      enablePayment: false,
      generatedLink: "",
      selectedConferenceCheckinDays: [],
      selectedPaymentCheckinDays: [],
      checkinDays: [],
      conferenceConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
      paymentConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
    };
  },
  setup() {
    const notificationStore = useNotificationStore();
    return {
      notificationStore,
    };
  },
  computed: {
    isGenerateLinkButtonDisabled() {
      return this.isConference
        ? !this.name || !this.startDate || !this.endDate || !this.venue
        : !this.name || !this.paymentStartDate || !this.paymentEndDate || !this.venue;
    },
  },
  watch: {
    startDate() {
      this.handleDateChange();
    },
    endDate() {
      this.handleDateChange();
    },
  },
  methods: {
    toggleLinkModal() {
      this.linkModalOpen = !this.linkModalOpen;
    },
    resetModal() {
      // Clear inputs (parent controls visibility)
      this.clearInputs();
      console.log("Modal has been reset!");
    },
    clearInputs() {
      console.log("Clearing inputs...");
      this.name = "";
      this.venue = "";
      this.startDate = null;
      this.endDate = null;
      this.paymentStartDate = null;
      this.paymentEndDate = null;
      this.enableConferenceCheckin = false;
      this.enablePaymentCheckin = false;
      this.enablePayment = false;
      this.checkinDays = [];
      this.selectedConferenceCheckinDays = [];
      this.selectedPaymentCheckinDays = [];
      console.log("Inputs cleared!");
    },
    handleDateChange() {
      if (this.startDate && this.endDate) {
        this.generateDateRange();
      }
    },
    generateDateRange() {
      if (!this.startDate || !this.endDate) {
        this.checkinDays = [];
        return;
      }
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      const days = [];
      for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        const formattedDate = d.toISOString().split("T")[0];
        days.push(formattedDate);
      }
      this.checkinDays = days;
      console.log("Generated Check-In Days (DB format):", this.checkinDays);
    },
    generateLink() {
      // Prepare data based on whether it’s a conference or payment
      const recordData = {
        name: this.name,
        startDate: this.isConference ? this.startDate : this.paymentStartDate,
        endDate: this.isConference ? this.endDate : this.paymentEndDate,
        venue: this.venue,
        type: this.isConference ? "conference" : "payment",
        enablePayment: this.enablePayment,
        enableCheckIn: this.isConference ? this.enableConferenceCheckin : this.enablePaymentCheckin,
        checkinDays: JSON.stringify(this.selectedConferenceCheckinDays),
      };

      console.log("Record data being sent to API:", recordData);

      axios.post(`${API_URL}/api/createRecord`, recordData)
        .then(response => {
          console.log("Backend response:", response.data);
          const { recordId, recordName, link, paymentLink } = response.data;

          // Emit the new conference details to the parent component
          this.$emit('conferenceCreated', {
            id: recordId,
            name: recordData.name,
            date: recordData.startDate,
            endDate: recordData.endDate,
            venue: recordData.venue,
            enableCheckIn: recordData.enableCheckIn,
            enablePayment: recordData.enablePayment,
          });

          if (!recordId || !recordName) {
            console.error("Missing data in API response:", response.data);
            return;
          }

          if (response.data.paymentRecordId) {
            console.log("Payment Record ID:", response.data.paymentRecordId);
          } else {
            console.log("No Payment Record ID in response");
          }

          this.generatedLink = paymentLink || link;
          console.log("Final Generated Link:", this.generatedLink);

          this.linkModalOpen = true;

          this.$notify({
            type: "success",
            message: `${this.isConference ? 'Conference' : 'Payment'} created successfully!`,
          });
          this.notificationStore.addNotification(
            'success',
            `${this.isConference ? 'Conference' : 'Payment'} created successfully!`
          );

          this.resetModal();
          // Emit close so that the parent hides the modal.
          this.$emit('close');
        })
        .catch((error) => {
          console.error("Error generating link:", error);
        });
    },
  },
};
</script>

<style>
/* Existing styles */

/* Override flatpickr calendar day selection */
.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.selected:hover {
  background: #cf2027 !important;
  border-color: #cf2027 !important;
}

.flatpickr-calendar .flatpickr-day:hover {
  background: #cf2027 !important;
}

/* Change selection highlight color in Flatpickr time picker */
.flatpickr-time .numInput::selection {
  background: #cf2027 !important;
  color: #fff !important;
}

/* Override time input styles */
.flatpickr-time input,
.flatpickr-time input:focus {
  border: 0px solid #cf2027 !important;
  box-shadow: 0 0 0 0px rgba(207, 32, 39, 0.5) !important;
  outline: none !important;
}

/* Override flatpickr time number input text color */
.flatpickr-calendar .flatpickr-time .numInput:focus {
  color: #cf2027 !important;
}

/* Hide default checkbox */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #c9c9c9;
  background-color: white;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
}

/* Custom checked state for checkbox */
input[type="checkbox"]:checked {
  background-color: #cf2027 !important;
  border-color: #cf2027 !important;
}

/* Custom checkmark for checkbox */
input[type="checkbox"]:checked::after {
  content: "\2713";
  color: transparent !important;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Arial, sans-serif;
}

/* Change focus outline for checkbox */
input[type="checkbox"]:focus {
  outline: 0px solid #cf2027 !important;
  box-shadow: 0 0 0px rgba(207, 32, 39, 0.8) !important;
}


/* When hovering over options (highlight state) */
.multiselect__option--highlight {
  background: #cf2027 !important;
  color: white !important;
}

/* When options are selected */
.multiselect__option--selected {
  background: #cf2027 !important;
  color: white !important;
}

/* Styling for the selected tags shown when the dropdown is closed */
.multiselect__tag {
  background: #cf2027 !important;
  color: white !important;
  border-color: #cf2027 !important;
}

.multiselect__option--create {
  display: none !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

::v-deep .multiselect__select-label {
  background: #cf2027 !important; /* your red color */
  color: #fff !important;
}

/* Or sometimes you need this one for the placeholder text color */
::v-deep .multiselect__placeholder {
  color: #cf2027 !important;
}

/* Styling for the select label */
.multiselect__select-label {
  background: #cf2027 !important;
  color: white !important;
}

/* Ensure the dropdown arrow is red */
.multiselect__select {
  background: #cf2027 !important;
}

/* Ensure the dropdown arrow is white */
.multiselect__select::before {
  border-color: white transparent transparent transparent !important;
}

.multiselect__tags:focus-within,
.multiselect__single:focus-within,
/* .multiselect__input:focus, */
.multiselect__input:focus-within {
  outline: 0px solid #cf2027 !important;
  outline-offset: 2px;
  box-shadow: none !important;
}

.multiselect__option--highlight .multiselect__option-helper {
  background-color: #cf2027 !important; /* Red */
  color: #fff !important;              /* White text */
}

.multiselect__option--highlight .multiselect__option-helper:hover {
  background-color: #cf2027 !important;
}

</style>

