console.log = function () {};

import { createApp } from "vue";
import { createPinia } from "pinia";
import { createRouter, createWebHistory } from "vue-router";
import { useNotificationStore } from "@/stores/notificationStore";
import "./components/index.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import Home_page from "./views/Home_page.vue";
import Event_page from "./views/Event_page.vue";
// import modal_page from "./views/modal_page.vue";
import PrimeVue from "primevue/config";
import user_regis from "./views/user_regis.vue";
import regis_successful from "./views/regis_successful.vue";
import Info_page from "./components/info_page.vue";
import AboutView from "./components/AboutView.vue";
import reimbursement_details from "./components/reimbursement_details.vue";
import check_in from "./components/check_in.vue";
import privacy_policy from "./components/privacy_policy.vue";
import reimbursement_page from "./components/reimbursement_page";
import info_reimbursements from "./components/info_reimbursements";
import Login_page from "./components/admin_regis/Login_page";
import signup_page from "./components/admin_regis/signup_page";
import checkin_overview from "@/components/checkin_overview";
import participants_data from "@/components/participants_data";
import checkin_successful from "@/components/checkin_successful";
// import nav_bar from "./components/nav_bar";
// import menu_bar from "./components/menu_bar";
import { setupTokenRefreshInterval } from "./utils/auth";

// Import FontAwesome core and components
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Import specific icons
import { faBars } from "@fortawesome/free-solid-svg-icons";

// Add icons to the library
library.add(faBars);

const routes = [
  { path: "/", component: Login_page },
  {
    path: "/Home_page",
    component: Home_page,
    meta: { requiresAuth: true }, // Protected route
  },
  { path: "/Event_page", component: Event_page },
  // { path: "/modal_page", component: modal_page },
  {
    path: "/user_regis/:recordId/:recordName",
    component: user_regis,
  },
  { path: "/user_regis", component: user_regis },
  {
    path: "/user_regis/:recordId/:recordName/:paymentRecordId?",
    name: "user_regis",
    component: user_regis, // Replace with your actual component
  },
  { path: "/regis_successful", component: regis_successful },
  { path: "/info_page", component: Info_page },
  {
    path: "/info",
    name: "info_page",
    component: Info_page,
    props: (route) => ({
      recordId: route.query.recordId,
      recordName: route.query.recordName,
      startDate: route.query.startDate,
      endDate: route.query.endDate,
      venue: route.query.venue,
    }),
  },
  { path: "/AboutView", component: AboutView },
  { path: "/reimbursement_details", component: reimbursement_details },
  {
    path: "/reimbursement_details",
    component: reimbursement_details,
    name: "ReimbursementDetails",
    props: (route) => ({ recordId: route.query.recordId }), // Pass the recordId as a prop to the component
  },
  { path: "/check_in", component: check_in },
  {
    path: "/check-in",
    name: "check_in",
    component: check_in,
    props: (route) => ({
      recordId: route.query.recordId,
      paymentRecordId: route.query.paymentRecordId,
      checkInDate: route.query.checkInDate,
    }),
  },
  { path: "/privacy_policy", component: privacy_policy },
  { path: "/reimbursement_page", component: reimbursement_page },
  { path: "/info_reimbursements", component: info_reimbursements },
  {
    path: "/info-reimbursements",
    name: "info_reimbursements",
    component: info_reimbursements,
    props: (route) => ({
      recordId: route.query.recordId,
      recordName: route.query.recordName,
      startDate: route.query.startDate,
      endDate: route.query.endDate,
      venue: route.query.venue,
      enableCheckIn: route.query.enableCheckIn,
    }),
  },
  { path: "/Login_page", component: Login_page },
  { path: "/signup_page", component: signup_page },
  // { path: "/nav_bar", component: nav_bar },
  // { path: "/menu_bar", component: menu_bar },
  { path: "/checkin_overview", component: checkin_overview },
  {
    path: "/checkin_overview/:recordId/:paymentRecordId",
    name: "CheckinOverview",
    component: checkin_overview,
    props: (route) => ({
      recordId: route.query.recordId,
      paymentRecordId: route.query.paymentRecordId,
    }),
  },
  { path: "/participants_data", component: participants_data },
  {
    path: "/participants_data/:recordName/:recordId/:paymentRecordId",
    name: "participants_data",
    component: participants_data,
    props: (route) => ({
      recordName: route.query.recordName,
      recordId: route.query.recordId,
      paymentRecordId: route.query.paymentRecordId,
    }),
  },
  { path: "/checkin_successful", component: checkin_successful },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
const pinia = createPinia();

// Use Pinia first before accessing any stores
app.use(pinia);

// Access the notification store after Pinia is active
const notificationStore = useNotificationStore();
notificationStore.loadNotifications();

app.use(router);
app.use(PrimeVue);
app.use(ElementPlus);

// Register FontAwesomeIcon component globally
app.component("font-awesome-icon", FontAwesomeIcon);

// Token refresh setup
const tokenRefreshInterval = setupTokenRefreshInterval();

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("authToken"); // Check for token

  if (to.meta.requiresAuth && !isAuthenticated) {
    // Redirect unauthenticated users to login
    next({ path: "/Login_page" });
  } else if (to.path === "/Login_page" && isAuthenticated) {
    // Redirect authenticated users away from login page
    next({ path: "/Home_page" });
  } else {
    // Allow access to the requested route
    next();
  }
});

// Mount app and setup cleanup
app.mount('#app')

window.addEventListener("unload", () => {
  clearInterval(tokenRefreshInterval);
});
