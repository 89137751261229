<template>
  <div class="w-full h-[100vh] bg-gradient-to-b from-orange-100 to-red-100 flex flex-col">
    <!-- Header -->
    <header class="p-4 border-b border-gray-400">
      <div class="relative">
        <h2 class="text-2xl font-bold pl-4" style="color: #cf2027;">
          Participants Data For {{ recordName }}
        </h2>
      </div>
    </header>
    <!-- Main Content -->
    <main class="flex-grow overflow-y-auto relative custom-scrollbar">
      <!-- Participants List -->
      <div class="p-6">
        <ul>
          <li
            v-for="(attendee, index) in attendees"
            :key="index"
            class="flex justify-between items-center border-b border-gray-400 py-4"
          >
            <span class="text-gray-800">{{ attendee.fullName }}</span>
            <button
            @click="viewDetails(attendee)"
            :disabled="attendee.verified"
            :class="[
                'px-4 py-2 rounded',
                attendee.verified
                ? 'bg-gray-400 text-gray-800 cursor-not-allowed'
                : 'bg-red-600 text-white hover:bg-red-700'
            ]"
            >
            {{ attendee.verified ? 'Verified' : 'View Details' }}
            </button>
          </li>
        </ul>
      </div>
    </main>

    <!-- Modal -->
    <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div class="bg-white rounded-lg shadow-lg p-6 w-2/5 max-h-[90vh] overflow-y-auto">
        <!-- Modal Header -->
        <div class="flex justify-between items-center border-b border-black pb-4">
          <h2 class="text-xl font-bold">{{ selectedAttendee.fullName }}</h2>
          <button @click="closeModal" class="text-gray-500 hover:text-black">
            &times;
          </button>
        </div>

        <!-- Modal Content -->
        <div class="mt-4">
          <p><strong>Telephone Number:</strong> {{ selectedAttendee.telephone || 'N/A' }}</p>
          <p><strong>Email:</strong> {{ selectedAttendee.email || 'N/A' }}</p>
          <p><strong>Designation:</strong> {{ selectedAttendee.designation || 'N/A' }}</p>
          <p><strong>Organisation:</strong> {{ selectedAttendee.organisation || 'N/A' }}</p>
          <p><strong>Bank Name:</strong> {{ selectedAttendee.bankName || 'N/A' }}</p>
          <p><strong>Branch:</strong> {{ selectedAttendee.branch || 'N/A' }}</p>
          <p><strong>Bank Account Number:</strong> {{ selectedAttendee.bankAccountNumber || 'N/A' }}</p>
          <p><strong>Reimbursement Amount:</strong> {{ selectedAttendee.reimbursementAmount || 'N/A' }} GHS</p>
          <!-- <p><strong>Amount to be Received:</strong> {{ calculateAmount(selectedAttendee.designation) }} GHS</p>
          <p><strong>Are you from outside Accra?</strong></p> -->
          <!-- <div class="flex gap-4 mt-2">
            <label>
              <input
                type="radio"
                name="outsideAccra"
                value="Yes"
                v-model="outsideAccra"
                class="mr-2"
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="outsideAccra"
                value="No"
                v-model="outsideAccra"
                class="mr-2"
              />
              No
            </label>
          </div> -->

          <!-- Flight Option if from outside Accra -->
          <!-- <div v-if="outsideAccra === 'Yes'" class="mt-4">
            <p><strong>Did you come by flight?</strong></p>
            <div class="flex gap-4 mt-2">
              <label>
                <input
                  type="radio"
                  name="cameByFlight"
                  value="Yes"
                  v-model="cameByFlight"
                  class="mr-2"
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="cameByFlight"
                  value="No"
                  v-model="cameByFlight"
                  class="mr-2"
                />
                No
              </label>
            </div>
          </div> -->

          <!-- Transport Reimbursement Details -->
          <!-- <div v-if="outsideAccra === 'Yes'" class="mt-4">
            <p><strong>Transport Reimbursement:</strong></p>
            <p v-if="cameByFlight === 'Yes'">
              Flight amount will be reimbursed in addition to <strong>600 GHS</strong>.
            </p>
            <p v-else-if="cameByFlight === 'No'">
              Flat rate of <strong>1000 GHS</strong>.
            </p>
          </div>
        </div> -->

        <!-- Modal Footer -->
        <div class="mt-6 flex justify-end">
          <button
            @click="showDisclaimer"
            class="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
          >
            Verify
          </button>
        </div>
      </div>
    </div>

    <!-- Disclaimer Popup -->
    <div v-if="showDisclaimerModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div class="bg-white rounded-lg shadow-lg p-6 w-2/3">
        <h2 class="text-xl font-bold mb-4">Disclaimer</h2>
        <p>
          By confirming, you acknowledge that you have read and understood the details provided,
          including how your data will be collected and used. By clicking "Confirm," you approve
          the use of your information for the stated purposes.
        </p>
        <div class="mt-6 flex justify-end gap-4">
          <button
            @click="closeDisclaimer"
            class="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            @click="confirmVerification"
            class="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { API_URL } from '../config';
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';

// Reactive variables
const route = useRoute();
const recordName = ref('');
const recordId = ref('');
const paymentRecordId = ref('');
const attendees = ref([]);
const showModal = ref(false);
const showDisclaimerModal = ref(false);
const selectedAttendee = ref({});
const outsideAccra = ref("No");
const cameByFlight = ref("No");
const isLoading = ref(false);
const reimbursementAmount = ref('N/A');


// Close the modal
const closeModal = () => {
  showModal.value = false;
};

// Calculate reimbursement amount
// const calculateAmount = (designation) => {
//   if (!designation || typeof designation !== 'string') return 300; // Default to 300 GHS
//   const roleLower = designation.toLowerCase();
//   if (/junior|intern|assistant/.test(roleLower)) return 300;
//   if (/technician|worker|representative|specialist/.test(roleLower)) return 300;
//   if (/administrative|clerk|receptionist|coordinator/.test(roleLower)) return 300;
//   if (/engineer|analyst|accountant|consultant|designer|researcher/.test(roleLower)) return 400;
//   if (/manager|supervisor|director|team lead/.test(roleLower)) return 500;
//   if (/chief|vp|president|executive|head|general/.test(roleLower)) return 600;
//   return 300; // Default
// };

// Fetch attendees and reimbursement data
const fetchAttendees = async (recordId, paymentRecordId) => { 
  console.log("Fetching attendees and reimbursements for:", { recordId, paymentRecordId });
  isLoading.value = true;
  try {
    const attendeeResponse = await axios.get(`${API_URL}/api/participants/${recordId}`);
    console.log("Attendee response received:", attendeeResponse.data);
    const reimbursementResponse = await axios.get(`${API_URL}/api/payments/${paymentRecordId}`);
    console.log("Reimbursement response received:", reimbursementResponse.data);
    // Fetch Reimbursement Amount from records table
    const recordResponse = await axios.get(`${API_URL}/api/reimbursement/${recordId}`);
    console.log("Record response received:", recordResponse.data);

    reimbursementAmount.value = recordResponse.data.reimbursementAmount || 'N/A';
    
    const attendeeData = attendeeResponse.data || [];
    const reimbursementData = reimbursementResponse.data || [];
    attendees.value = attendeeData.map((attendee) => {
      const reimbursement = reimbursementData.find((reim) => reim.telephone === attendee.TelNumber) || {};
      return {
      fullName: attendee.Fullname || 'N/A',
      telephone: attendee.TelNumber || 'N/A',
      email: attendee.EmailAddress || 'N/A',
      designation: attendee.Designation || 'N/A',
      organisation: attendee.Organisation || 'N/A',
      bankName: reimbursement.bankName || 'N/A',
      branch: reimbursement.branch || 'N/A',
      bankAccountNumber: reimbursement.bankAccountNumber || 'N/A',
      reimbursementAmount: reimbursementAmount.value,
      verified: attendee.verified || false, // Add this line
    };
});
  console.log("Processed attendees:", attendees.value);
  } catch (error) {
    console.error('Error fetching attendees or reimbursements:', error);
  } finally {
    isLoading.value = false;
  }
};

// Show attendee details
const viewDetails = (attendee) => {
  selectedAttendee.value = attendee;
  outsideAccra.value = "No";
  cameByFlight.value = "No";
  showModal.value = true;
};

// Disclaimer modal logic
const showDisclaimer = () => { showDisclaimerModal.value = true; };
const closeDisclaimer = () => { showDisclaimerModal.value = false; };

const confirmVerification = () => {
  showDisclaimerModal.value = false;
  showModal.value = false;

  // Mark the selected attendee as verified
const attendeeIndex = attendees.value.findIndex(
    (attendee) => attendee.fullName === selectedAttendee.value.fullName
  );
  if (attendeeIndex !== -1) {
    attendees.value[attendeeIndex].verified = true;

    try {
      // Send verification status to the backend
        axios.post(`${API_URL}/api/participants/verify`, {
        telephone: attendees.value[attendeeIndex].telephone,
        verified: true,
      });
    } catch (error) {
      console.error("Error updating verification status:", error);
    }
  }

  alert("Verification confirmed.");
};

// On component mount
onMounted(() => {
  console.log("Component mounted. Route query:", route.query);
  recordName.value = route.query.recordName || 'Unknown Conference';
  recordId.value = route.query.recordId || '';
  paymentRecordId.value = route.query.paymentRecordId || '';
  console.log("Initialized values:", { recordName: recordName.value, recordId: recordId.value, paymentRecordId: paymentRecordId.value });
  if (recordId.value && paymentRecordId.value) fetchAttendees(recordId.value, paymentRecordId.value);
  
});
</script>

<style>
/* Hide the default radio button styling */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none; /* For Safari */
  width: 20px;
  height: 20px;
  border: 2px solid red;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transitions */
}

/* Style for checked state */
input[type="radio"]:checked {
  background-color: red; /* Inner circle color when selected */
  border-color: red; /* Keep the border color red */
}

/* Style for focus state */
input[type="radio"]:focus {
  outline: 2px solid rgba(255, 0, 0, 0.5); /* Red outline for focus */
}

/* Style for hover state */
input[type="radio"]:hover {
  border-color: darkred; /* Slightly darker red on hover */
}
</style>