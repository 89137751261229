<template>
  <div class="w-full h-16 bg-white shadow-md flex items-center justify-end px-5 relative">
    <div class="flex items-center space-x-4">

      <!-- Search Input -->
      <div class="relative">
        <el-input
          v-model="searchQuery"
          placeholder="Search records..."
          class="w-64"
          @input="onInputChange"
          clearable
        >
          <template #prefix>
            <el-icon class="text-gray-400">
              <Search />
            </el-icon>
          </template>
        </el-input>
      </div>

      <!-- Notification Bell -->
      <div class="relative">
        <div class="cursor-pointer" @click="toggleNotifications">
          <i class="fa-regular fa-bell text-gray-600"></i>
          <span
            v-if="unreadCount > 0"
            class="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full px-1"
          >
            {{ unreadCount }}
          </span>
        </div>

        <!-- Notifications Dropdown -->
        <div
          v-if="showNotifications"
          class="absolute top-full mt-2 right-0 w-65 bg-white shadow-lg rounded-md p-4 z-50"
          style="transform: translateX(50px)"
        >
          <h3 class="text-lg font-semibold">Notifications</h3>
          <ul class="mt-2 divide-y divide-gray-200">
            <li
              v-for="notification in activeNotifications"
              :key="notification.id"
              class="py-2 px-3 flex justify-between items-start"
              :class="{
                'bg-blue-100': notification.type === 'new',
                'bg-green-100': notification.type === 'reminder',
                'bg-red-100': notification.type === 'deleted',
              }"
            >
              <div>
                <p class="text-sm font-bold capitalize">{{ notification.type }}</p>
                <p class="text-xs text-gray-600">{{ notification.message }}</p>
                <p class="text-xs text-gray-400">
                  {{ new Date(notification.timestamp).toLocaleString() }}
                </p>
              </div>
              <button
                class="text-xs text-red-500 hover:underline"
                @click="removeNotification(notification.id)"
              >
                Dismiss
              </button>
            </li>
          </ul>
          <p v-if="activeNotifications.length === 0" class="mt-4 text-center text-gray-500">
            No notifications
          </p>
        </div>
      </div>

      <!-- Username Display -->
      <span>{{ username }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "../config";
import { ref, onMounted, computed } from "vue";
import { useNotificationStore } from "@/stores/notificationStore";
import { storeToRefs } from "pinia";
import { Search } from "@element-plus/icons-vue";
import { ElIcon } from "element-plus";
import { useRoute } from "vue-router";

// Pinia search store
import { useSearchStore } from "@/stores/searchStore";

export default {
  components: { Search, ElIcon },
  setup() {
    const username = ref("Loading...");

    // Fetch username from backend
    const fetchUsername = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) throw new Error("No auth token available");

        const response = await axios.get(`${API_URL}/api/auth/username`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        username.value = response.data.username;
      } catch (error) {
        if (error.response?.status === 403) {
          console.error("Token is invalid or expired. Please log out and log in.");
          localStorage.removeItem("authToken");
          localStorage.removeItem("refreshToken");
        } else {
          console.error("Error fetching username:", error.message);
          username.value = "Error";
        }
      }
    };

    onMounted(() => {
      fetchUsername();
      fetchData();
    });

    // Notification store
    const notificationStore = useNotificationStore();
    const { activeNotifications, unreadCount } = storeToRefs(notificationStore);

    // Toggle notifications
    const showNotifications = ref(false);
    const autoHideTimer = ref(null);

    const toggleNotifications = () => {
      showNotifications.value = !showNotifications.value;
      if (showNotifications.value) {
        // Mark notifications as read (reset the unread count)
        notificationStore.markAllAsRead();
      }
      resetAutoHideTimer();
    };

    const removeNotification = (id) => {
      notificationStore.removeNotification(id);
      resetAutoHideTimer();
    };

    const hideNotifications = () => {
      showNotifications.value = false;
    };

    const resetAutoHideTimer = () => {
      if (autoHideTimer.value) clearTimeout(autoHideTimer.value);
      if (showNotifications.value) {
        autoHideTimer.value = setTimeout(hideNotifications, 5000);
      }
    };

    // Pinia search store
    const searchStore = useSearchStore();
    // Bind input to store's query
    const searchQuery = computed({
      get: () => searchStore.query,
      set: (val) => searchStore.setQuery(val),
    });

    const onInputChange = () => {
      console.log("Search query changed:", searchQuery.value);
    };

    // Data fetching for conferences and payments (as before)
    const conferences = ref([]);
    const payments = ref([]);
    const loading = ref(false);

    const route = useRoute();
    const currentPage = computed(() => route.name);

    async function fetchData() {
      loading.value = true;
      try {
        const [conferenceResponse, paymentResponse] = await Promise.all([
          axios.get(`${API_URL}/api/by_date`),
          axios.get(`${API_URL}/api/payments/sortedByDate`),
        ]);

        conferences.value = Array.isArray(conferenceResponse.data.records)
          ? conferenceResponse.data.records.map((item) => ({
              id: item.id,
              name: item.name || "Unnamed Conference",
              type: "conference",
            }))
          : [];

        payments.value = Array.isArray(paymentResponse.data.records)
          ? paymentResponse.data.records.map((item) => ({
              id: item.id,
              name: item.name || "Unnamed Payment",
              type: "payment",
            }))
          : [];

        searchStore.setRecords([...conferences.value, ...payments.value]);
      } catch (error) {
        console.error("Error fetching data:", error);
        conferences.value = [];
        payments.value = [];
      } finally {
        loading.value = false;
      }
    }

    return {
      username,
      activeNotifications,
      unreadCount, // Expose unreadCount for the badge
      showNotifications,
      toggleNotifications,
      removeNotification,
      searchQuery,
      onInputChange,
      conferences,
      payments,
      loading,
      currentPage,
    };
  },
};
</script>

<style scoped>
/* Remove any leftover highlight styling if you had it before. */

/* Basic input override for El-UI */
:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 1px var(--el-color-danger) inset !important;
}
:deep(.el-input__wrapper:hover) {
  box-shadow: 0 0 0 1px var(--el-color-danger) inset !important;
}
::v-deep(.el-input__inner:focus) {
  box-shadow: 0 0 0 0px #ff4d4f inset !important;
}
</style>
