<template>
  <div class="p-5 space-x-6 w-[100vw] bg-gradient-to-b from-orange-100 to-red-100 h-[100vh] flex justify-center items-center">
    <div class="w-full bg-white p-10 rounded-lg shadow-md h-96 overflow-y-auto relative" :style="{ height: '95%' }">

      <!-- Back Button -->
      <button @click="goBack" class="absolute top-4 left-4">
        <i class="fas fa-arrow-left text-xl text-gray-500 hover:text-gray-700"></i>
      </button>

      <!-- Download Button -->
      <button 
        @click="downloadData" 
        class="absolute top-4 right-20 mr-5 flex items-center justify-center text-gray-500 hover:text-gray-700">
        <i class="fas fa-download text-xl"></i>
      </button>

      <div v-if="isGeneratingPDF" class="fixed inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50 z-50">
        <div class="text-center">
          <p class="text-lg font-semibold text-gray-600">Generating PDF, please wait...</p>
          <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full mt-4"></div>
        </div>
      </div>                  

      <!-- Validation Button -->
      <button 
        @click="validateAccounts" 
        :disabled="isValidating" 
        class="absolute top-4 right-4 px-3 py-1 bg-red-600 text-white rounded-full text-xs hover:bg-red-700 transition-colors flex items-center gap-2"
      >
        <!-- Loader SVG -->
        <svg v-if="isValidating" 
            class="animate-spin h-4 w-4 text-white" 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24">
          <circle 
            class="opacity-25" 
            cx="12" 
            cy="12" 
            r="10" 
            stroke="currentColor" 
            stroke-width="4">
          </circle>
          <path 
            class="opacity-75" 
            fill="currentColor" 
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
        
        <!-- Button Text -->
        <span>{{ isValidating ? 'Validating...' : 'Validate' }}</span>
      </button>

      <!-- Activity Info Section -->
     <div class="flex items-center mb-5">
        <img :src="logo" alt="Conference Logo" class="w-16 h-16 object-contain mr-4" />
        <div>
          <h2 class="text-sm">Activity Title: {{ localRecordName }}</h2>
          <p class="text-sm">Date: {{ formattedDateRange }}</p>
          <p class="text-sm">Venue: {{ venue }}</p>
        </div>
      </div>

      <!-- Activity Info Section -->
      <div class="mb-5 text-center mt-2">
        <h2 class="text-sm font-semibold">PARTICIPANT REIMBURSEMENT FORM</h2>
      </div>

      <!-- Table Section -->
      <table class="w-full border-collapse border border-gray-200 mt-8">
        <thead>
          <tr>
            <th class="px-4 py-2 border border-gray-200">Full Name On Account</th>
            <th class="px-4 py-2 border border-gray-200">Telephone</th>
            <th class="px-4 py-2 border border-gray-200">Bank Name</th>
            <th class="px-4 py-2 border border-gray-200">Branch</th>
            <th class="px-4 py-2 border border-gray-200">Bank Account Number</th>
            <template v-if="enableCheckIn">
              <th v-for="date in checkinDates" :key="date" class="px-4 py-2 border border-gray-200">
                {{ formatDate(date) }}
              </th>
            </template>
            <th v-else class="px-4 py-2 border border-gray-200">Signature</th>
            <th class="px-4 py-2 border border-gray-200">Reimbursement Amount</th>
            <th class="px-4 py-2 border border-gray-200">Email</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="attendee in attendees" :key="attendee.recordId">
            <td class="px-4 py-2 border border-gray-200">{{ attendee.fullNameOnAccount }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.telephone }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.bankName }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.branch }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.bankAccountNumber }}</td>

            <template v-if="enableCheckIn">
              <td v-for="date in checkinDates" :key="date" class="px-4 py-2 border border-gray-200">
                <span v-if="attendee.checkinStatus && attendee.checkinStatus[date]">
                  <img v-if="attendee.signature" 
                       :src="attendee.signature" 
                       alt="Signature" 
                       class="object-contain" 
                       style="max-height: 50px; max-width: 100px;"
                       @error="handleImageError(attendee)" />
                </span>
                <span v-else>Not Present</span>
              </td>
            </template>
            <td v-else class="px-4 py-2 border border-gray-200">
              <img v-if="attendee.signature"
                   :src="attendee.signature"
                   alt="Signature"
                   class="object-contain"
                   style="max-height: 50px; max-width: 100px;"
                   @error="handleImageError(attendee)" />
              <span v-else>No Signature</span>
            </td>
            <td class="px-4 py-2 border border-gray-200">
              {{ reimbursementAmount || 'N/A' }}
            </td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.email }}</td>
          </tr>
          <tr v-if="attendees.length === 0">
            <td :colspan="getColspan" class="px-4 py-2 text-center">No reimbursement record found.</td>
          </tr>
        </tbody>
      </table> 
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useNotificationStore } from "@/stores/notificationStore";
import { API_URL } from '../config';
import logo from "@/assets/logo.png";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "Info_reimbursements",
  setup() {
    const notificationStore = useNotificationStore(); 
    return { notificationStore };
  },
  data() {
    return {
      attendees: [],
      checkinDates: [], // This will now be populated from the backend
      isLoading: true,
      localRecordId: null,
      localRecordName: null,
      startDate: null,
      endDate: null,
      venue: null,
      errorMessage: "",
      isGeneratingPDF: false,
      enableCheckIn: false,
      isValidating: false,
      reimbursementAmount: null,
      logo,
    };
  },
  computed: {
    formattedDateRange() {
      if (this.startDate && this.endDate) {
        return `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
      }
      return "N/A";
    },

    getColspan() {
      const baseColumns = 8; 
      return this.enableCheckIn ? baseColumns + this.checkinDates.length - 1 : baseColumns;
    },
  },

  async mounted() {
    const recordId = this.recordId || this.$route.query.recordId;
    const recordName = this.recordName || this.$route.query.recordName;
    const startDate = this.startDate || this.$route.query.startDate;
    const endDate = this.endDate || this.$route.query.endDate;
    const venue = this.venue || this.$route.query.venue;

    if (recordId) {
      this.localRecordId = recordId;
      this.localRecordName = recordName;
      this.startDate = startDate;
      this.endDate = endDate;
      this.venue = venue;

      // Fetch checkinDays from the backend
      await this.fetchCheckinDays(recordId);

      this.fetchAttendees(recordId);
    } else {
      console.error('Record ID is missing');
    }
    
  const imagePath = require('@/assets/logo.png'); // Adjust path if needed
  const img = new Image();
  img.src = imagePath;
  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    this.logo = canvas.toDataURL('image/png'); // Save base64 string
  };
  img.onerror = () => {
    console.error('Error loading logo image');
  };
  },

  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      return `${this.getDayWithOrdinal(day)} ${month} ${year}`;
    },
    
    getDayWithOrdinal(day) {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = day % 100;
      return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    },

    async fetchCheckinDays(recordId) {
      try {
        const response = await axios.get(`${API_URL}/api/records/${recordId}`);
        const recordDetails = response.data[0]; // Assuming the response is an array with one record
        this.enableCheckIn = recordDetails.enableCheckIn === 1 || recordDetails.enableCheckIn === true;

        this.checkinDates = recordDetails.checkinDays || [];

        this.reimbursementAmount = recordDetails.reimbursementAmount || null;
      } catch (error) {
        console.error('Error fetching checkinDays:', error);
        this.checkinDates = [];
      }
    },

    fetchAttendees(recordId) {
      this.isLoading = true;
      axios.get(`${API_URL}/api/payments/${recordId}`)
        .then(async (response) => {
          const attendeeData = response.data 
            ? (Array.isArray(response.data) ? response.data : [response.data])
            : [];

          this.attendees = attendeeData.map(attendee => ({
            recordId: attendee.id || null,
            fullNameOnAccount: attendee.fullNameOnAccount || 'N/A',
            telephone: attendee.telephone || 'N/A',
            bankName: attendee.bankName || 'N/A',
            branch: attendee.branch || 'N/A',
            bankAccountNumber: attendee.bankAccountNumber || 'N/A',
            signature: attendee.signature || null,
            email: attendee.email || 'N/A',
          }));

          if (this.enableCheckIn && this.checkinDates.length > 0) {
            this.attendees = await Promise.all(
              this.attendees.map(async (attendee) => {
                const checkinStatus = {};
                for (const date of this.checkinDates) {
                  checkinStatus[date] = await this.checkCheckInStatus(attendee.telephone, date);
                }
                return {
                  ...attendee,
                  checkinStatus,
                };
              })
            );
          }

          if (this.attendees.length === 0) {
            console.warn('No attendees found for this record');
          }
        })
        .catch(error => {
          console.error('Error fetching attendees:', error);
          this.errorMessage = "Failed to fetch attendees. Please check the console for more details.";
          this.attendees = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async checkCheckInStatus(TelNumber, checkInDate) {
      try {
        const response = await axios.get(`${API_URL}/api/checkinStatus`, {
          params: { TelNumber, checkInDate },
        });
        return response.data.checkInStatus === 1;
      } catch (error) {
        console.error(
          `Error checking check-in status for ${TelNumber} on ${checkInDate}:`,
          error
        );
        return false;
      }
    },

    goBack() {
      this.$router.go(-1);
    },
    
    async downloadData() {
      this.isGeneratingPDF = true;

      try {
        const headers = [
          'Full Name On Account',
          'Telephone',
          'Bank Name',
          'Branch',
          'Bank Account Number',
          'Reimbursement Amount',
          ...(this.enableCheckIn ? this.checkinDates.map(date => this.formatDate(date)) : ['Signature']),
          'Email'
        ];

        const tableBody = [headers];
        const columnWidths = headers.map(() => 'auto');

        for (const attendee of this.attendees) {
          let signatureImage = 'No Signature';

          if (attendee.signature) {
            try {
              if (attendee.signature.startsWith('http')) {
                signatureImage = await this.getBase64ImageFromURL(attendee.signature);
              } else if (attendee.signature.startsWith('data:image')) {
                signatureImage = attendee.signature;
              }
            } catch (error) {
              console.warn(`Error processing signature for ${attendee.fullNameOnAccount}:`, error);
            }
          }

          const rowData = [
            attendee.fullNameOnAccount || "N/A",
            attendee.telephone || "N/A",
            attendee.bankName || "N/A",
            attendee.branch || "N/A",
            attendee.bankAccountNumber || "N/A",
            this.reimbursementAmount || "N/A"
          ];

          if (this.enableCheckIn) {
            this.checkinDates.forEach(date => {
              rowData.push(
                attendee.checkinStatus && attendee.checkinStatus[date]
                  ? { image: signatureImage, width: 50, height: 25, alignment: 'center' }
                  : { text: "Not Present", alignment: 'center' }
              );
            });
          } else {
            rowData.push(
              signatureImage.startsWith('data:image')
                ? { image: signatureImage, width: 50, height: 25, alignment: 'center' }
                : { text: "No Signature", alignment: 'center' }
            );
          }

          rowData.push(attendee.email || "N/A");
          tableBody.push(rowData);
        }

        const docDefinition = {
          pageOrientation: 'landscape',
          pageMargins: [40, 120, 40, 40],
           header: () => {
    return {
      columns: [
        // Logo
        {
          image: this.logo,
          width: 60,
          height: 60,
          alignment: 'left',
          margin: [40, 20, 0, 0],
        },
        // Activity Info
        {
          stack: [
            { text: `Activity Title: ${this.localRecordName || "N/A"}`, fontSize: 14, bold: true },
            { text: `Date: ${this.formattedDateRange || "N/A"}`, fontSize: 14, bold: true },
            { text: `Venue: ${this.venue || "N/A"}`, fontSize: 14, bold: true },
          ],
          alignment: 'left',
          margin: [50, 30, 0, 0],
        },
      ],
      margin: [10, 20, 10, 0],
    };
  },
          content: [
            { text: "PARTICIPANT REIMBURSEMENT FORM", 
            bold: true,
            fontSize: 14,
            alignment: 'center', 
            margin: [0, 5, 0, 10] },
            {
              table: {
                headerRows: 1,
                widths: columnWidths,
                body: tableBody,
              },
            },
          ],
          styles: {
            subheader: {
              fontSize: 12,
              margin: [0, 5, 0, 5]
            }
          },
          defaultStyle: {
            fontSize: 10
          }
        };

        const pdfName = this.localRecordName ? `${this.localRecordName}.pdf` : 'attendees.pdf';
        pdfMake.createPdf(docDefinition).download(pdfName);
        
        this.$notify({ type: 'success', message: 'PDF generated successfully!' });
      } catch (error) {
        console.error("Error generating PDF:", error);
        this.$notify({ type: 'error', message: 'Failed to generate PDF. Please try again.' });
      } finally {
        this.isGeneratingPDF = false;
      }
    }, 

    handleImageError(attendee) {
      console.error(`Error loading signature for attendee: ${attendee.fullNameOnAccount}`);
      attendee.signature = null;
    },

    async getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          try {
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
          } catch (error) {
            reject(`Image conversion failed: ${error}`);
          }
        };

        img.onerror = (error) => {
          reject(`Failed to load image: ${url} - Error: ${error}`);
        };

        img.src = url;
      });
    },  

    async validateAccounts() {
      if (this.isValidating) return;

      this.isValidating = true;
      try {
        const validationResults = await Promise.all(
          this.attendees.map(async (attendee) => {
            const bankAccountNumber = this.cleanAccountNumber(
              attendee.bankAccountNumber
            );
            const bankName = attendee.bankName;

            if (!bankAccountNumber || !bankName) {
              return {
                account: attendee,
                isValid: false,
                message: "Missing bank account or bank name.",
              };
            }

            const bankCode = await this.fetchBankCode(bankName);

            if (!bankCode) {
              return {
                account: attendee,
                isValid: false,
                message: `No bank code found for bank: ${bankName}`,
              };
            }

            const { isValid, message } = await this.resolveAccount(
              bankAccountNumber,
              bankCode
            );
            return { account: attendee, isValid, message };
          })
        );

        const invalidAccounts = validationResults.filter(
          (result) => !result.isValid
        );

        if (invalidAccounts.length === 0) {
          this.$notify({
            type: "success",
            message: "All bank accounts are valid!",
          });
          this.notificationStore.addNotification(
            "success",
            "All bank accounts are valid!"
          );
        } else {
          const invalidMessages = invalidAccounts
            .map((result) => `${result.account.bankAccountNumber}: ${result.message}`)
            .join(", ");
          this.$notify({
            type: "error",
            message: `Invalid accounts found: ${invalidMessages}`,
          });
          this.notificationStore.addNotification(
            "error",
            `Invalid accounts found: ${invalidMessages}`
          );
        }
      } catch (error) {
        console.error("Error during validation:", error);
        this.$notify({
          type: "error",
          message: "An error occurred during validation.",
        });
        this.notificationStore.addNotification(
          "error",
          "An error occurred during validation."
        );
      } finally {
        this.isValidating = false;
      }
    },

    async fetchBankCode(bankName) {
      try {
        const response = await axios.get(`${API_URL}/api/banks/code`, {
          params: { name: bankName },
        });
        return response.data.bankCode;
      } catch (error) {
        console.error(`Error fetching bank code for ${bankName}:`, error);
        return null;
      }
    },

    async resolveAccount(accountNumber, bankCode) {
      try {
        const response = await axios.post(`${API_URL}/api/resolve`, {
          account_number: accountNumber,
          bank_code: bankCode,
        });
        console.log("Resolved Account Data:", response.data);

        return { isValid: true };
      } catch (error) {
        console.error(
          `Error resolving account ${accountNumber} for bank code ${bankCode}:`,
          error.response ? error.response.data : error.message
        );
        return { isValid: false, message: error.response?.data?.error || "Account validation failed" };
      }
    },

    cleanAccountNumber(accountNumber) {
      return accountNumber.replace(/\D/g, "");
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>