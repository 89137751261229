<template>
  <div class="w-full h-[100vh] bg-gradient-to-b from-orange-100 to-red-100 flex flex-col">
    <!-- Navigation Bar -->
    <menu_bar />
    <!-- Main Content Area -->
    <div class="flex-grow p-3">
      <div class="w-full h-full max-h-[88vh] mx-auto bg-white rounded-lg shadow-md flex flex-col">
        <!-- Header -->
        <header class="p-4 border-b">
          <h2 class="text-2xl font-bold pl-4" style="color: #cf2027;">
            Reimbursement Records
          </h2>
        </header>
        <!-- Main Content Area -->
        <main class="flex-grow overflow-y-auto relative custom-scrollbar">
          <!-- Payment List -->
          <div class="p-6">
            <ul v-if="payments.length > 0" class="divide-y divide-gray-200">
              <li
                v-for="payment in payments"
                :key="payment.id"
                class="py-4 hover:bg-red-100"
                @contextmenu.prevent="showContextMenu(payment, $event)"
              >
                <div class="flex justify-between">
                  <div class="flex-1">
                    <p
                      class="text-sm font-semibold"
                      @mouseover="hoveredPayment = payment"
                      @mouseleave="hoveredPayment = null"
                    >
                      <span v-html="highlightedOrName(payment)"></span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <p v-else class="text-gray-500">
              No upcoming records of reimbursements available
            </p>
          </div>
          <!-- Context Menu -->
          <div
            v-if="showMenu"
            ref="contextMenu"
            class="absolute z-10 bg-white shadow-md p-2 rounded-lg context-menu"
            :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }"
          >
            <ul class="py-2">
              <li
                @click="viewReimbursementDetails(clickedPayment)"
                class="hover:bg-red-100 px-4 py-2 cursor-pointer"
              >
                View Reimbursement Details
              </li>
              <li
                @click="openAddAmountModal(clickedPayment)"
                class="hover:bg-red-100 px-4 py-2 cursor-pointer"
              >
                Add/Edit Reimbursement Amount
              </li>
            </ul>
          </div>
          <!-- Reimbursement Amount Modal -->
          <div
            v-if="showAmountModal"
            class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20"
          >
            <div class="bg-white p-6 rounded-lg shadow-lg">
              <h3 class="text-lg font-bold mb-4">Add Reimbursement Amount</h3>
              <input
                v-model="reimbursementAmount"
                type="number"
                min="0"
                step="0.01"
                placeholder="Enter amount"
                class="border p-2 rounded w-full focus:outline-red-500 focus:border-red-500"
                @input="validateAmount"
              />
              <div class="flex justify-end mt-4">
                <button
                  @click="saveReimbursementAmount"
                  class="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Save
                </button>
                <button
                  @click="closeAmountModal"
                  class="bg-gray-300 text-black px-4 py-2 rounded ml-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </main>
        <!-- Footer Navigation -->
        <nav_bar />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { API_URL } from '../config';
import { useRecordStore } from '@/stores/recordStore';

// Import the Pinia Search Store
import { useSearchStore } from "@/stores/searchStore";
import { useNotificationStore } from "@/stores/notificationStore";

import nav_bar from "@/components/nav_bar.vue";
import menu_bar from "@/components/menu_bar.vue";

const router = useRouter();

const payments = ref([]);
const hoveredPayment = ref(null);
const clickedPayment = ref(null);
const showMenu = ref(false);
const menuPosition = ref({ x: 0, y: 0 });

// Access the searchStore
const searchStore = useSearchStore();
const notificationStore = useNotificationStore();

// recordStore
const recordStore = useRecordStore();

const showAmountModal = ref(false);
const reimbursementAmount = ref(null);

// Highlight helper
function highlightedOrName(payment) {
  // We look up by `payment.id` now
  const found = searchStore.getFilteredRecords().find(
    (item) => item.id === payment.id && item.type === "payment"
  );
  return found?.highlightedName || payment.name;
}

// Fetch payments sorted by date
// const fetchPayments = async () => {
//   try {
//     const response = await axios.get(`${API_URL}/api/payments/sortedByDate`);
//     console.log("API Full Response:", response);

//     // Check if records field exists and is an array
//     if (response.data && Array.isArray(response.data.records)) {
//       // We'll store `id` consistently
//       payments.value = response.data.records.map((payment) => ({
//         id: payment.id,                 // <-- use the same key "id"
//         name: payment.name,
//         date: payment.startDate,
//         endDate: payment.endDate,
//         venue: payment.venue,
//         type: payment.type,
//         reimbursementAmount: payment.reimbursementAmount || 0,
//       }));
//       console.log("Mapped Payments:", payments.value);
//     } else {
//       console.error(
//         "Unexpected response format: 'records' is not an array",
//         response.data
//       );
//       payments.value = [];
//     }
//   } catch (error) {
//     console.error("Error fetching payments:", error);
//     if (error.response) {
//       console.log("Axios Error Details:", error.response);
//     }
//   }
// };

const fetchPayments = async () => {
  try {
    await recordStore.fetchPayments();
    payments.value = recordStore.payments.map(payment => ({
      id: payment.id,
      name: payment.name,
      date: payment.startDate,
      endDate: payment.endDate,
      venue: payment.venue,
      type: payment.type,
      reimbursementAmount: payment.reimbursementAmount || 0,
    }));
  } catch (error) {
    console.error("Error fetching payments:", error);
    notificationStore.addNotification('error', 'Failed to fetch payments.');
  }
}; 

// Show the context menu
const showContextMenu = (payment, event) => {
  try {
    console.log("Clicked Payment:", payment);
    clickedPayment.value = payment;
    if (!payment?.id) {
      console.error("Payment ID is required to show the context menu.");
      return;
    }

    const container = document.querySelector(".overflow-y-auto");
    const containerRect = container.getBoundingClientRect();

    menuPosition.value = {
      x: event.clientX - containerRect.left + container.scrollLeft,
      y: event.clientY - containerRect.top + container.scrollTop,
    };
    
    showMenu.value = true;
    window.addEventListener("click", hideContextMenu);
  } catch (error) {
    console.error("Error showing context menu:", error);
  }
};

const hideContextMenu = () => {
  showMenu.value = false;
  window.removeEventListener("click", hideContextMenu);
};

// Navigate to reimbursement details page
const viewReimbursementDetails = (payment) => {
  if (!payment || !payment.id) {
    console.error("No record ID found for this payment");
    return;
  }

  router.push({
    path: "/Info_reimbursements",
    query: {
      recordId: payment.id,      // pass id
      recordName: payment.name,
      startDate: payment.date,
      endDate: payment.endDate,
      venue: payment.venue,
      enableCheckIn: payment.enableCheckIn,
    },
  });
};

// Reimbursement amount
const openAddAmountModal = (payment) => {
  clickedPayment.value = payment;
  reimbursementAmount.value = payment.reimbursementAmount || 0;
  showAmountModal.value = true;
};

const closeAmountModal = () => {
  showAmountModal.value = false;
  reimbursementAmount.value = null;
};

// const saveReimbursementAmount = async () => {
//   try {
//     if (!clickedPayment.value?.id) {
//       console.error("No record ID available");
//       alert("Error: Record ID is missing");
//       return;
//     }

//     const amount = Number(reimbursementAmount.value);
//     if (isNaN(amount) || amount < 0) {
//       console.error("Invalid amount:", reimbursementAmount.value);
//       alert("Please enter a valid amount");
//       return;
//     }

//     const payload = {
//       recordId: clickedPayment.value.id, // pass id as recordId to the server
//       reimbursementAmount: amount,
//     };

//     console.log("Sending payload:", payload);

//     const response = await axios.post(`${API_URL}/api/payments/addAmount`, payload);
//     console.log("Server response:", response.data);

//     // Update the local list with the new amount
//     const paymentIndex = payments.value.findIndex(
//       (p) => p.id === clickedPayment.value.id
//     );
//     if (paymentIndex !== -1) {
//       payments.value[paymentIndex].reimbursementAmount = amount;
//     }

//     showAmountModal.value = false;

//     this?.$notify?.({
//       type: "success",
//       title: "Reimbursement Amount Saved",
//       text: "The reimbursement amount has been updated successfully.",
//     });
//     notificationStore.addNotification(
//       "success",
//       "Reimbursement amount saved successfully"
//     );
//   } catch (error) {
//     console.error("Error saving reimbursement amount:", error);
//     let errorMessage = "Failed to save reimbursement amount";
//     if (error.response?.data?.message) {
//       errorMessage = error.response.data.message;
//     }
//     alert(errorMessage);
//   }
// };

const saveReimbursementAmount = async () => {
  try {
    if (!clickedPayment.value?.id) {
      console.error("No record ID available");
      notificationStore.addNotification('error', 'Record ID is missing.');
      return;
    }

    const amount = Number(reimbursementAmount.value);
    if (isNaN(amount) || amount < 0) {
      console.error("Invalid amount:", reimbursementAmount.value);
      notificationStore.addNotification('error', 'Please enter a valid amount.');
      return;
    }

    const payload = {
      recordId: clickedPayment.value.id,
      reimbursementAmount: amount,
    };

    const response = await axios.post(`${API_URL}/api/payments/addAmount`, payload);
    console.log("Server response:", response.data);

    const paymentIndex = payments.value.findIndex(
      (p) => p.id === clickedPayment.value.id
    );
    if (paymentIndex !== -1) {
      payments.value[paymentIndex].reimbursementAmount = amount;
    }

    showAmountModal.value = false;
    notificationStore.addNotification('success', 'Reimbursement amount saved successfully.');
  } catch (error) {
    console.error("Error saving reimbursement amount:", error);
    notificationStore.addNotification('error', 'Failed to save reimbursement amount.');
  }
};

const validateAmount = (event) => {
  let value = event.target.value;
  if (value < 0) {
    reimbursementAmount.value = 0;
  }
};

// Component lifecycle
onMounted(fetchPayments);

onUnmounted(() => {
  window.removeEventListener("click", hideContextMenu);
});
</script>

<script>
export default {
  name: "App",

  methods: {
    goToEventPage() {
      this.$router.push("/Event_page");
    },
    goToHomePage() {
      this.$router.push("/");
    },
    goToInfoReimbursements() {
      this.$router.push("/info_reimbursements");
    },
  },
};
</script>

<style>
/* Same styles as before */
.payment-name {
  font-size: 12px;
  font-weight: bold;
  padding: 10px 0;
}

.context-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  padding: 10px;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #fde9e9;
}

.modal-overlay {
  z-index: 50;
}

.modal-container {
  position: relative;
  z-index: 50;
  padding: 20px;
}

.context-menu button {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

input[type="number"]:focus {
  outline: none !important; /* Removes default outline */
  border: 2px solid red !important; /* Sets border to red */
  box-shadow: 0 0 0px rgba(255, 0, 0, 0.5) !important; /* Red glow effect */
}

</style>