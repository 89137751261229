import { defineStore } from "pinia";

export const useNotificationStore = defineStore("notificationStore", {
  state: () => ({
    notifications: [], // Array to store all notifications
    unreadCount: 0, // Count of unread notifications
  }),

  actions: {
    // Add a new notification with TTL
    addNotification(type, message, ttl = 60000) {
      const expiration = Date.now() + ttl; // Calculate expiration timestamp
      const notification = {
        id: Date.now(), // Unique ID for each notification
        type,
        message,
        timestamp: new Date(),
        expiration, // Store expiration time
      };

      this.notifications.push(notification);
      this.unreadCount++; // Increment unread count

      this.saveNotifications(); // Persist notifications
    },

    // Remove a notification by its ID
    removeNotification(id) {
      this.notifications = this.notifications.filter((n) => n.id !== id);
      this.saveNotifications(); // Persist updated notifications
    },

    // Clear the unread count when notifications are viewed
    markAllAsRead() {
      this.unreadCount = 0;
    },

    // Remove expired notifications
    removeExpiredNotifications() {
      const now = Date.now();
      this.notifications = this.notifications.filter(
        (notification) => notification.expiration > now
      );
      this.saveNotifications(); // Persist after cleanup
    },

    // Save notifications to localStorage
    saveNotifications() {
      localStorage.setItem("notifications", JSON.stringify(this.notifications));
    },

    // Load notifications from localStorage and remove expired ones
    loadNotifications() {
      const savedNotifications = localStorage.getItem("notifications");
      if (savedNotifications) {
        this.notifications = JSON.parse(savedNotifications);
        this.removeExpiredNotifications(); // Remove expired notifications on load
      }
    },
  },

  getters: {
    // Return all active (non-expired) notifications
    activeNotifications: (state) => {
      const now = Date.now();
      return state.notifications.filter(
        (notification) => notification.expiration > now
      );
    },
  },
});
