<template>
  <div class="p-5 space-x-6 w-full bg-gradient-to-b from-orange-100 to-red-100 h-screen flex justify-center items-center">
    <div class="w-full bg-white p-10 rounded-lg shadow-md h-96 overflow-y-auto relative" :style="{ height: '95%' }">

      <!-- Back Button -->
      <button @click="goBack" class="absolute top-4 left-4">
        <i class="fas fa-arrow-left text-xl text-gray-500 hover:text-gray-700"></i>
      </button>

      <!-- Download Button -->
      <button 
        @click="downloadData" 
        class="absolute top-4 right-20 flex items-center justify-center text-gray-500 hover:text-gray-700">
        <i class="fas fa-download text-xl"></i>
      </button>


      <!-- Activity Info Section -->
      <div class="flex items-center mb-5">
        <img :src="logo" alt="Conference Logo" class="w-16 h-16 object-contain mr-4" />
        <div>
          <h2 class="text-sm">Activity Title: {{ localRecordName }}</h2>
          <p class="text-sm">Date: {{ formattedDateRange }}</p>
          <p class="text-sm">Venue: {{ venue }}</p>
        </div>
      </div>

      <!-- Activity Info Section -->
      <div class="mb-5 text-center mt-2">
        <h2 class="text-sm font-semibold">PARTICIPANT REGISTRATION FORM</h2>
      </div>

      <!-- Table Section -->
      <table class="w-full border-collapse border border-gray-200 mt-8">
        <thead>
          <tr>
            <th class="px-4 py-2 border border-gray-200">Full Name</th>
            <th class="px-4 py-2 border border-gray-200">Organisation</th>
            <th class="px-4 py-2 border border-gray-200">Email Address</th>
            <th class="px-4 py-2 border border-gray-200">Designation</th>
            <th class="px-4 py-2 border border-gray-200">Tel Number</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(attendee, index) in attendees" :key="index">
            <td class="px-4 py-2 border border-gray-200">{{ attendee.Fullname }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.Organisation }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.EmailAddress }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.Designation }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.TelNumber }}</td>
          </tr>
          <tr v-if="attendees.length === 0">
            <td colspan="4" class="px-4 py-2 text-center">No participants found for this conference.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { API_URL } from '../config';
import logo from "@/assets/logo.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

  export default {
  name: "Info_page",
  props: ['recordId', 'recordName'],
  data() {
    return {
      attendees: [],
      checkinDays: [], // Change this line
      reimbursementAmount: null,
      isLoading: true,
      localRecordId: null,
      localRecordName: null,
      startDate: null,
      endDate: null,
      venue: null,
      logo,
    };
  },
  computed: {
    formattedDateRange() {
      if (this.startDate && this.endDate) {
        return `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
      }
      return "N/A";
    },
  },
  mounted() {
    const { recordId, recordName, startDate, endDate, venue } = this.$route.query;

    if (recordId) {
      this.localRecordId = recordId;
      this.localRecordName = recordName;
      this.startDate = startDate;
      this.endDate = endDate;
      this.venue = venue;
      this.fetchAttendees(recordId);
    } else {
      console.error('Record ID is missing');
    }

  const imagePath = require('@/assets/logo.png'); // Adjust path if needed
  const img = new Image();
  img.src = imagePath;
  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    this.logo = canvas.toDataURL('image/png'); // Save base64 string
  };
  img.onerror = () => {
    console.error('Error loading logo image');
  };

  },
  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();

      return `${this.getDayWithOrdinal(day)} ${month} ${year}`;
    },
    getDayWithOrdinal(day) {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = day % 100;
      return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    },
    fetchAttendees(recordId) {
      this.isLoading = true;
      axios.get(`${API_URL}/api/participants/${recordId}`)
        .then(response => {
          this.attendees = response.data;
        })
        .catch(error => {
          console.error('Error fetching attendees:', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async fetchReimbursementAmount(recordId) {
    try {
      const response = await axios.get(`${API_URL}/payments/getAmount/${recordId}`);
      this.reimbursementAmount = response.data.reimbursementAmount;
      this.checkinDays = response.data.checkinDays || []; // Fetch checkinDays
    } catch (error) {
      console.error('Error fetching reimbursement amount and checkin days:', error);
      this.errorMessage = "Failed to fetch reimbursement amount and checkin days.";
    }
  },
    goBack() {
      this.$router.go(-1);
    },
    
  async downloadData() {
  // Debugging: Log the values of formattedDateRange and venue
  console.log('Formatted Date Range:', this.formattedDateRange);
  console.log('Venue:', this.venue);

  const docDefinition = {
  pageOrientation: 'landscape',
  // Give top/bottom margins enough space so header is clearly visible:
  pageMargins: [40, 120, 40, 40],

  header: () => {
    return {
      columns: [
        // Logo
        {
          image: this.logo,
          width: 60,
          height: 60,
          alignment: 'left',
          margin: [40, 20, 0, 0],
        },
        // Activity Info
        {
          stack: [
            { text: `Activity Title: ${this.localRecordName || "N/A"}`, fontSize: 14, bold: true },
            { text: `Date: ${this.formattedDateRange || "N/A"}`, fontSize: 14, bold: true },
            { text: `Venue: ${this.venue || "N/A"}`, fontSize: 14, bold: true },
          ],
          alignment: 'left',
          margin: [50, 30, 0, 0],
        },
      ],
      margin: [10, 20, 10, 0],
    };
  },

  content: [
    { text: '', margin: [0, 10] },
    {
      text: "PARTICIPANT REGISTRATION FORM",
      bold: true,
      fontSize: 14,
      alignment: 'center',
      margin: [0, 5, 0, 10]
    },
    {
      table: {
        headerRows: 1,
        widths: ['20%', '20%', '20%', '20%', '20%'],
        body: [
          ['Full Name', 'Organisation', 'Email Address', 'Designation', 'Tel Number'],
          ...this.attendees.map(attendee => [
            attendee.Fullname || "N/A",
            attendee.Organisation || "N/A",
            attendee.EmailAddress || "N/A",
            attendee.Designation || "N/A",
            attendee.TelNumber || "N/A",
          ]),
        ],
      },
      layout: {
        hLineWidth: () => 1,
        vLineWidth: () => 1,
        hLineColor: () => '#000000',
        vLineColor: () => '#000000',
      },
      margin: [40, 10, 0, 0],
    },
  ],
};

  const pdfName = this.localRecordName ? `${this.localRecordName}.pdf` : 'attendees.pdf';
  pdfMake.createPdf(docDefinition).download(pdfName);
}
  },
};
</script>


