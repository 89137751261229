<template>
  <div class="relative overflow-hidden"></div>

  <!-- Background content -->
  <div class="p-5 space-x-6 w-[100vw] bg-gradient-to-r from-red-600 to-orange-500 h-[100vh] flex justify-center items-center">
    <!-- Your content goes here -->
  </div>

  <!-- Loading Spinner Overlay -->
  <div
    v-if="isLoading"
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[60]"
  >
    <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
  </div>

  <!-- Modal -->
  <div id="crud-modal" tabindex="-1" aria-hidden="true" class="fixed inset-0 flex justify-center items-center z-50">
    <div class="bg-white bg-opacity-10 backdrop-filter absolute inset-0"></div>

    <div class="relative bg-white bg-opacity-30 rounded-lg shadow-lg w-[70vw] max-h-[calc(80vh-0rem)] p-6 flex flex-col justify-between overflow-hidden">
      
      <!-- Modal content -->
      <div class="flex flex-col flex-grow overflow-hidden">
        <!-- Modal header -->
        <div class="flex items-center justify-between border-b pb-4 mb-4">
          <img :src="logo" alt="Logo" class="h-10 w-auto mr-8" />
          <h3 class="text-lg font-semibold text-white flex-grow break-word">Reimbursement Information Form</h3>
        </div>

        <!-- Disclaimer -->
        <p class="text-sm text-white mb-4 text-center">
          By submitting this form, you consent to Aurum Institute Ghana collecting and processing your personal data in accordance with the Data Protection Act, 2012 (Act 843). Your information will be kept confidential and used only for reimbursement purposes. For more information, click <a href="privacy_policy" target="_blank" class="text-blue-700 underline">here</a>.
        </p>

        <!-- Modal body -->
        <div ref="formContainer" class="flex-grow overflow-auto custom-scrollbar pr-4 mb-4">
          <form @submit.prevent="register" class="flex flex-col space-y-4">

            <!-- Full Name On Account -->
            <div style="padding-left: 0.5rem;">
              <label for="full-name-on-account" class="block text-sm font-medium text-white">Full Name On Account</label>
              <input v-model="fullNameOnAccount" 
              @focus="hideFilteredBanks"
              :disabled="submitted" 
              type="text" 
              id="full-name-on-account" 
              class="mt-1 block w-full rounded-md bg-white bg-opacity-20 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 focus:border-transparent" required />
            </div>

            <!-- Telephone Number -->
            <div style="padding-left: 0.5rem;">
              <label for="telephone" class="block text-sm font-medium text-white">Telephone Number</label>
              <input v-model="telephone" 
              @focus="hideFilteredBanks" 
              @input="filterNonNumeric" 
              :disabled="submitted" 
              type="tel" 
              id="telephone" 
              pattern="[0-9]*" 
              class="mt-1 block w-full rounded-md bg-white bg-opacity-20 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 focus:border-transparent" required />
            </div>

            <!-- Bank Name -->
            <div style="padding-left: 0.5rem;">
              <label for="bank-name" class="block text-sm font-medium text-white">Bank Name</label>
              <input
                v-model="searchQuery"
                @input="filterBanks"
                type="text"
                id="bank-name"
                placeholder="Start typing to filter bank names"
                :disabled="submitted"
                class="mt-1 block w-full rounded-md bg-white bg-opacity-20 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 focus:border-transparent"
                required/>
              <!-- Filtered Bank Names -->
              <ul v-if="filteredBanks.length" class="bg-white rounded shadow-md mt-1">
                <li
                  v-for="bank in filteredBanks"
                  :key="bank.bankCode"
                  @click="selectBank(bank.bankName)"
                  class="cursor-pointer px-4 py-2 hover:bg-gray-200">
                  {{ bank.bankName }}
                </li>
              </ul>
            </div>

            <!-- Branch -->
            <div style="padding-left: 0.5rem;">
              <label for="branch" class="block text-sm font-medium text-white">Branch</label>
              <input v-model="branch" 
              @focus="hideFilteredBanks" 
              :disabled="submitted" 
              type="text" 
              id="branch" 
              class="mt-1 block w-full rounded-md bg-white bg-opacity-20 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 focus:border-transparent" required />
            </div>

            <!-- Bank Account Number -->
            <div style="padding-left: 0.5rem;">
              <label for="bank-account-number" class="block text-sm font-medium text-white">Bank Account Number</label>
              <input v-model="bankAccountNumber"
              @focus="hideFilteredBanks"
              :disabled="submitted" 
              type="text" 
              id="bank-account-number" 
              autocomplete="off" 
              class="mt-1 block w-full rounded-md bg-white bg-opacity-20 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 focus:border-transparent" required />
            </div>

            <!-- signature -->
            <div style="padding-left: 0.5rem;">
              <label for="signature" class="block text-sm font-medium text-white">Signature</label>
              <input v-model="signature" 
              @focus="hideFilteredBanks"
              @click="opensignatureModal" 
              type="text" 
              id="signature"
              autocomplete="off"  
              class="mt-1 block w-full rounded-md bg-white bg-opacity-20 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 focus:border-transparent" required />
            </div>

          <!-- signature Modal -->
          <transition name="fade">
            <div v-if="showsignaturePad" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div class="bg-gray-700 p-5 rounded-lg">
                <Vue3signature ref="signaturePad" :width="500" :height="300" />
                <div class="flex justify-end mt-4">
                  <button type="button" @click="clear" class="bg-white text-black p-2 rounded mr-2">Clear</button>
                  <button type="button" @click="save" class="bg-white text-black p-2 rounded mr-2">Save</button>
                  <button type="button" @click="cancelsignatureModal" class="bg-white text-black p-2 rounded">Cancel</button>
                </div>
              </div>
            </div>
          </transition>
            
            <!-- Email -->
            <div style="padding-left: 0.5rem;">
              <label for="email" class="block text-sm font-medium text-white">Email</label>
              <input v-model="email" 
              :disabled="submitted"
              @focus="hideFilteredBanks" 
              type="email" 
              id="email" 
              class="mt-1 block w-full rounded-md bg-white bg-opacity-20 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 focus:border-transparent" required />
            </div>

            <!-- Registration status -->
            <p class="text-white mt-4" v-if="message">{{ message }}</p>

            <!-- Submit button -->
            <div class="flex justify-center pt-4 flex-shrink-0">
              <button type="submit" class="bg-red-600 text-white w-full px-8 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700" :disabled="submitted">
                Register
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Footer -->
      <div class="border-t mt-4 pt-4">
        <p class="text-sm text-white text-center">
          &copy; 2024 Aurum Institute Ghana. All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import logo from "@/assets/logo.png";
import Vue3signature from 'vue3-signature';
import { API_URL } from '../config';

export default {
  components: {
    Vue3signature
  },
  data() {
    return {
      fullNameOnAccount: this.$route.query.fullNameOnAccount || "",
      telephone: this.$route.query.telephone || "",
      email: this.$route.query.email || "",
      // bankName: this.bankName,
      branch: this.branch,
      bankAccountNumber: this.bankAccountNumber,
      signature: null,
      message: "",
      submitted: false,
      showsignaturePad: false,
      logo: logo,
      banks: [],
      searchQuery: "",
      filteredBanks: [], 
      bankName: "",
      isLoading: false,
    };
  },

  mounted() {
    
    this.fetchBanks();

    // Extract paymentRecordId and recordName from the route parameters
    this.recordId = decodeURIComponent(this.$route.query.recordId || "");
    this.paymentRecordId = decodeURIComponent(this.$route.query.paymentRecordId || "");
    this.recordName = decodeURIComponent(this.$route.query.recordName || "");
    

    // Debugging logs
    console.log("Payment Record ID:", this.paymentRecordId);
    console.log("Record Name:", this.recordName);
  },

  methods: {
     async fetchBanks() {
        // Fetch the list of banks from the backend
        const response = await axios.get(`${API_URL}/api/banks`);
        this.banks = response.data;
    },

    
    filterBanks() {
  if (!this.searchQuery) {
    this.filteredBanks = []; // Clear the list if there's no input
    return;
  }

  // Match against 'bankName' as per the backend structure
  this.filteredBanks = this.banks.filter((bank) =>
    bank.bankName?.toLowerCase().includes(this.searchQuery.toLowerCase())
  );
  },

    selectBank(bankName) {
      // Set the selected bank name and clear the filtered list
      this.searchQuery = bankName;
      this.bankName = bankName;
      this.filteredBanks = [];
    },

    filterNonNumeric() {
      this.telephone = this.telephone.replace(/\D/g, "");
    },

    hideFilteredBanks() {
    this.filteredBanks = [];
    },

    opensignatureModal() {
      this.showsignaturePad = true;
      this.$nextTick(() => {
        const canvas = this.$refs.signaturePad.$el.querySelector('canvas');
        if (canvas) {
          // Set canvas dimensions to match the container or fixed size
          canvas.width = this.$refs.signaturePad.$el.clientWidth;
          canvas.height = this.$refs.signaturePad.$el.clientHeight;
          // You may need to reinitialize or clear the canvas here
          this.$refs.signaturePad.clear(); // Assuming Vue3signature has a clear method to reset the canvas
        }
      });
    },
    clear() {
      this.$refs.signaturePad.clear();
    },
    save() {
      const signatureData = this.$refs.signaturePad.save();
      console.log(signatureData); // Log the Base64 signature data
      this.signature = signatureData; // Update the `signature` field with the saved data
      this.showsignaturePad = false; // Close the signature modal
    },
    cancelsignatureModal() {
    this.showsignaturePad = false;
    },

    async register() {
      console.log("[register] Called register() method");
      this.isLoading = true;
      console.log("Register method triggered");

      this.submitted = true;

      // Validate inputs
      if (!this.fullNameOnAccount || !this.telephone || !this.bankName || !this.branch || !this.bankAccountNumber || !this.email || !this.signature ) {
        this.message = "All fields are required.";
        this.submitted = false;
        return;
      }

      try {
        // API call
        const response = await axios.post(`${API_URL}/api/registerPayment`, {
          recordId: this.paymentRecordId,
          fullNameOnAccount: this.fullNameOnAccount,
          telephone: this.telephone,
          bankName: this.bankName,
          branch: this.branch,
          bankAccountNumber: this.bankAccountNumber,
          signature: this.signature,
          email: this.email
        });

        // Handle success based on backend response
        if (response.status === 201) {
          console.log("Backend response:", response.data);
          this.message = response.data.message || "Registration successful!";
          this.$router.replace("/regis_successful");
        }
      } catch (error) {
        this.isLoading = false;
        // Handle different types of errors
        if (error.response) {
          // Backend error
          console.error("Backend error response:", error.response.data);
          if (error.response.status === 409) {
            this.message = error.response.data.error || "Telephone number already exists for this record.";
          } else if (error.response.status === 400) {
            this.message = error.response.data.error || "All fields are required.";
          } else {
            this.message = error.response.data.error || "Failed to register payment details.";
          }
        } else if (error.request) {
          // No response from backend
          console.error("No response from backend:", error.request);
          this.message = "No response from the server. Please try again later.";
        } else {
          // Error setting up the request
          console.error("Error setting up request:", error.message);
          this.message = "An error occurred. Please try again.";
        }
      }

      this.submitted = false;
    }
  }
};
</script>


<style scoped>
.custom-scrollbar-container {
  padding-right: 1rem;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-scrollbar-container:hover .custom-scrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar-container:active .custom-scrollbar::-webkit-scrollbar-thumb {
  opacity: 1;
}

input {
  outline: none;
  box-shadow: none;
  border: none;
}

input:focus {
  outline: none;
  box-shadow: 0 0 0 2px none;
  border-color: none;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.375rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.form-group {
  margin-bottom: 1rem;
}

input:focus, 
select:focus, 
textarea:focus {
  outline: 1px solid red; /* Set the focus outline color to red */
  box-shadow: 0 0 1px red; /* Optional: Add a red glow */
}

</style>
