<template>
  <div class="flex min-h-screen overflow-auto">
    <div class="relative w-[1rem] bg-gradient-to-b from-orange-200 to-red-200 shadow-md"></div>
    <div class="p-5 space-x-6 w-full bg-gradient-to-r from-white-100 to-white-100 h-screen flex justify-center items-center">
      <div class="p-4 space-y-2 md:space-y-2 sm:p-2">
        <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 class="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">Sign Into your account</h2>
          </div>

          <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" @submit.prevent="onSubmit">
              <div>
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                <div class="mt-2">
                  <input 
                    id="email" 
                    name="email" 
                    type="email" 
                    v-model="formData.email" 
                    autocomplete="email" 
                    required 
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" 
                  />
                </div>
              </div>

              <!-- Password Field -->
              <div>
                <div class="flex items-center justify-between">
                  <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                </div>
                <div class="mt-2 relative">
                  <input 
                    :type="showPassword ? 'text' : 'password'" 
                    id="password" 
                    name="password" 
                    v-model="formData.password" 
                    autocomplete="current-password" 
                    required 
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" 
                  />
                  <button 
                    type="button" 
                    @click="togglePasswordVisibility" 
                    class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-600 hover:text-gray-900 focus:outline-none"
                  >
                    {{ showPassword ? 'Hide' : 'Show' }}
                  </button>
                </div>
              </div>

              <div>
                <button 
                  type="submit" 
                  class="flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 items-center gap-2"
                  :disabled="loading"
                >
                  <svg v-if="loading" 
                  class="animate-spin h-5 w-5 text-white" 
                  xmlns="http://www.w3.org/2000/svg" fill="none" 
                  viewBox="0 0 24 24">

                    <circle 
                    class="opacity-25" 
                    cx="12" 
                    cy="12" 
                    r="10" 
                    stroke="currentColor" 
                    stroke-width="4">
                  </circle>
                    <path 
                    class="opacity-75" 
                    fill="currentColor" 
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span>{{ loading ? 'Signing in...' : 'Sign in' }}</span>
                </button>
              </div>
            </form>

            <p 
            class="mt-10 text-center text-sm text-gray-500">
              Don't have an account yet?
              <button 
              @click="goToSignUp" class="font-semibold leading-6 text-red-600 hover:text-red-500 hover:underline">
                Create an account
              </button>
            </p>

            <p v-if="errorMessage" 
            class="text-sm text-red-600 mt-4 text-center">
            {{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from '../../config';

export default {
  name: "LoginPage",
  data() {
    return {
      showPassword: false,
      loading: false,
      formData: {
        email: "",
        password: ""
      },
      errorMessage: ""
    };
  },
  methods: {
    goToSignUp() {
      this.$router.push("/signup_page");
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async onSubmit() {
      this.loading = true;
      this.errorMessage = "";
      
      try {
        const response = await axios.post(`${API_URL}/api/auth/login`, this.formData);
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        this.$router.push("/Home_page");
      } catch (error) {
        console.error("Login Error:", error.response?.data || error.message);
        this.errorMessage = error.response?.data?.message || "An error occurred during login.";
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
/* Hide Microsoft Edge built-in reveal/clear icons */
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none !important;
}
</style>