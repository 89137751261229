<template>
  <div class="w-full h-screen bg-gradient-to-b from-orange-100 to-red-100 flex flex-col">
    <!-- Navigation Bar Component -->
    <menu_bar />
    <!-- Spacing Between the Top Bar and Main Container -->
    <div class="flex-grow p-3">
      <!-- Main Content Container -->
      <div class="w-full h-full max-h-screen mx-auto bg-white rounded-lg shadow-md flex flex-col">
        <!-- Modal Component (only one instance) -->
        <modal_page v-if="isModalOpen" :visible="isModalOpen" @close="toggleModal" @conferenceCreated="addConference" />

        <!-- Header -->
        <header class="p-4 border-b">
          <div class="relative flex items-center justify-between">
            <h2 class="text-2xl font-bold pl-4" style="color: #cf2027;">Conference Records</h2>
            <!-- Icon button placed in header -->
            <button
              @click="toggleModal"
              class="fa-solid fa-circle-plus fa-2x"
              style="color: #cf2027;"
              type="button"
            ></button>
          </div>
        </header>

        <!-- Main Content Area with Scrollbar -->
        <main class="flex-grow overflow-y-auto relative custom-scrollbar">
          <div class="p-6">
            <ul v-if="sortedConferences.length > 0" class="divide-y divide-gray-200">
              <li
                v-for="conference in sortedConferences"
                :key="conference.id"
                class="py-4 hover:bg-red-100"
                @contextmenu.prevent="showContextMenu(conference, $event)"
                @touchstart="handleTouchStart(conference, $event)"
              >
                <div class="flex justify-between">
                  <div class="flex-1">
                    <p
                      class="text-sm font-semibold"
                      @mouseover="hoveredConference = conference"
                      @mouseleave="hoveredConference = null"
                    >
                      <span v-html="highlightedOrName(conference)"></span>
                    </p>
                  </div>
                  <div class="flex-1 text-right">
                    <p class="text-sm text-gray-500">{{ formatDate(conference.date) }}</p>
                    <p
                      class="text-xs font-semibold"
                      :class="{
                        'text-green-500': getConferenceStatus(conference.date, conference.endDate) === 'Upcoming',
                        'text-blue-500': getConferenceStatus(conference.date, conference.endDate) === 'Ongoing',
                        'text-red-500': getConferenceStatus(conference.date, conference.endDate) === 'Past'
                      }"
                    >
                      {{ getConferenceStatus(conference.date, conference.endDate) }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <p v-else class="text-gray-500">No conferences available</p>
          </div>
          <!-- Context Menu -->
          <div
            v-if="showMenu"
            ref="contextMenu"
            class="absolute z-10 bg-white shadow-md p-2 rounded-lg context-menu"
            :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }"
          >
            <ul class="py-2">
              <li @click="copyConferenceLink" class="hover:bg-red-100 px-4 py-2 cursor-pointer">
                Copy Conference Link
              </li>
              <li @click="viewParticipantsInfo(clickedConference)" class="hover:bg-red-100 px-4 py-2 cursor-pointer">
                View Conference Info
              </li>
              <li @click="deleteConference(clickedConference.id)" class="hover:bg-red-100 px-4 py-2 cursor-pointer">
                Delete Conference
              </li>
              <!-- Check-in Link Option -->
              <li v-if="enableCheckIn" @click="generateCheckinLink">
                Copy Check-in Link
              </li>
              <li v-else>
                <span>Check In is not Available</span>
              </li>
              <!-- Additional option -->
              <li @click="viewParticipantsData(clickedConference)" class="hover:bg-red-100 px-4 py-2 cursor-pointer">
                View Participants Data
              </li>
            </ul>
          </div>
        </main>
        <!-- Nav bar -->
        <nav_bar />
      </div>
    </div>
  </div>
</template>

<script setup>
// (The script remains unchanged)
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { FRONTEND_URL } from '../config';
import { useRecordStore } from '@/stores/recordStore';
import { getCurrentInstance } from 'vue';

// Import components
import modal_page from "@/components/modal_page.vue";
import nav_bar from "@/components/nav_bar.vue";
import menu_bar from "@/components/menu_bar.vue";

// Import the Pinia Search Store
import { useSearchStore } from '@/stores/searchStore';
import { useNotificationStore } from "@/stores/notificationStore";

const router = useRouter();
const conferences = ref([]);
const hoveredConference = ref(null);
const clickedConference = ref(null);
const isModalOpen = ref(false);
const showMenu = ref(false);
const menuPosition = ref({ x: 0, y: 0 });
const searchStore = useSearchStore();
const notificationStore = useNotificationStore();
const recordStore = useRecordStore();
const { proxy } = getCurrentInstance();

const toggleModal = () => {
  isModalOpen.value = !isModalOpen.value;
};

let touchTimeout = null;

const fetchConferences = async () => {
  try {
    await recordStore.fetchConferences();
    conferences.value = recordStore.conferences.map(conference => ({
      id: conference.id,
      name: conference.name,
      date: conference.startDate,
      enableCheckIn: conference.enableCheckIn === 1 || conference.enableCheckIn === true,
      enablePayment: conference.enablePayment === 1 || conference.enablePayment === true,
      endDate: conference.endDate,
      venue: conference.venue,
      paymentRecordId: conference.paymentRecordId,
    }));
  } catch (error) {
    console.error('Error fetching conferences:', error);
    notificationStore.addNotification('error', 'Failed to fetch conferences.');
  }
};

const getConferenceStatus = (startDate, endDate) => {
  const currentDate = new Date();
  const conferenceStartDate = new Date(startDate);
  const conferenceEndDate = new Date(endDate);
  
  if (currentDate < conferenceStartDate) {
    return 'Upcoming';
  } else if (currentDate >= conferenceStartDate && currentDate <= conferenceEndDate) {
    return 'Ongoing';
  } else {
    return 'Past';
  }
};

const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

const sortedConferences = computed(() => {
  return conferences.value.slice().sort((a, b) => {
    const dateAStart = new Date(a.date);
    const dateBStart = new Date(b.date);
    const statusA = getConferenceStatus(a.date, a.endDate);
    const statusB = getConferenceStatus(b.date, b.endDate);
    const statusPriority = { 'Ongoing': 2, 'Upcoming': 1, 'Past': 0 };

    if (statusPriority[statusA] !== statusPriority[statusB]) {
      return statusPriority[statusB] - statusPriority[statusA];
    }
    return dateAStart - dateBStart;
  });
});

const enableCheckIn = computed(() => {
  return clickedConference.value ? clickedConference.value.enableCheckIn : false;
});

const showContextMenu = (conference, event) => {
  console.log("Conference Data:", conference);
  console.log("Payment Record ID:", conference.paymentRecordId);
  event.preventDefault();
  clickedConference.value = conference;
  const container = document.querySelector(".overflow-y-auto");
  if (!container) return;
  const containerRect = container.getBoundingClientRect();
  menuPosition.value = {
    x: event.clientX - containerRect.left + container.scrollLeft,
    y: event.clientY - containerRect.top + container.scrollTop,
  };
  showMenu.value = true;
  window.addEventListener('click', hideContextMenuOutside);
};

const handleTouchStart = (conference, event) => {
  touchTimeout = setTimeout(() => showContextMenu(conference, event), 600);
};

const handleTouchEnd = () => { clearTimeout(touchTimeout); };

window.addEventListener('touchend', handleTouchEnd);

function highlightedOrName(conference) {
  const found = searchStore.getFilteredRecords().find(
    item => item.id === conference.id && item.type === 'conference'
  );
  return found?.highlightedName || conference.name;
}

const hideContextMenu = () => {
  showMenu.value = false;
  window.removeEventListener('click', hideContextMenuOutside);
};

const hideContextMenuOutside = (event) => {
  const contextMenu = document.querySelector('.context-menu');
  if (contextMenu && !contextMenu.contains(event.target)) {
    hideContextMenu();
  }
};

const copyConferenceLink = () => {
  if (clickedConference.value) {
    let conferenceLink = `${FRONTEND_URL}/user_regis?recordId=${clickedConference.value.id}&recordName=${encodeURIComponent(clickedConference.value.name)}`;
    if (clickedConference.value.paymentRecordId) {
      conferenceLink += `&paymentRecordId=${clickedConference.value.paymentRecordId}`;
    }
    navigator.clipboard.writeText(conferenceLink)
      .then(() => alert("Conference link copied to clipboard!"))
      .catch(err => {
        console.error("Failed to copy link:", err);
        alert("Failed to copy the link. Please try again.");
      });
  }
  hideContextMenu();
};

const generateCheckinLink = () => {
  if (clickedConference.value && clickedConference.value.paymentRecordId) {
    let checkinLink = `${FRONTEND_URL}/checkin_overview?recordId=${clickedConference.value.id}&paymentRecordId=${clickedConference.value.paymentRecordId}`;
    navigator.clipboard.writeText(checkinLink)
      .then(() => alert("Check-in link copied to clipboard!"))
      .catch(err => {
        console.error("Failed to copy link:", err);
        alert("Failed to copy the check-in link. Please try again.");
      });
  } else {
    alert("Check-in link cannot be generated. PaymentRecordId is missing.");
  }
  hideContextMenu();
};

const viewParticipantsInfo = (conference) => {
  router.push({
    path: '/info_page',
    query: {
      recordId: conference.id,
      recordName: conference.name,
      startDate: conference.date,
      endDate: conference.endDate,
      venue: conference.venue,
    },
  });
};

const deleteConference = async (id) => {
  try {
    const confirmDelete = confirm("Are you sure you want to delete this conference?");
    if (!confirmDelete) return;
    await recordStore.deleteConference(id);
    await fetchConferences(); 
    proxy.$notify({
      type: 'success',
      message: 'Conference deleted successfully!',
    });
    notificationStore.addNotification('success', 'Conference deleted successfully!');
  } catch (error) {
    console.error("Error deleting conference:", error);
    proxy.$notify({
      type: 'error',
      message: 'Failed to delete the conference.',
    });
    notificationStore.addNotification('error', 'Failed to delete the conference.');
  }
  hideContextMenu();
};

const viewParticipantsData = (conference) => {
  router.push({
    path: '/participants_data',
    query: {
      recordName: conference.name,
      recordId: conference.id,
      paymentRecordId: conference.paymentRecordId || '',
    },
  });
  hideContextMenu();
};

const addConference = (newConference) => {
  conferences.value.unshift(newConference);
  console.log("Updated conferences list:", conferences.value);
  fetchConferences();
};

onMounted(() => {
  fetchConferences();
  window.addEventListener('touchend', handleTouchEnd);
});

onUnmounted(() => {
  window.removeEventListener('touchend', handleTouchEnd);
});
</script>

<style>
.custom-scrollbar {
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb,
.custom-scrollbar:active::-webkit-scrollbar-thumb {
  opacity: 1;
}

.context-menu {
  max-width: 90vw;
  width: 200px;
}

@media (max-width: 640px) {
  .context-menu {
    width: 80vw;
  }
}
</style>
