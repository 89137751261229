// src/utils/auth.js
import { API_URL } from '../config';

export const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expiry = payload.exp * 1000; // Convert to milliseconds
    return Date.now() >= expiry;
  } catch (error) {
    console.error("Error parsing token:", error);
    return true;
  }
};

export const refreshAuthToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  try {
    const response = await fetch(`${API_URL}/api/auth/refresh-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (!response.ok) {
      throw new Error("Token refresh failed");
    }

    const data = await response.json();
    localStorage.setItem("authToken", data.token);
    return data.token;
  } catch (error) {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    throw error;
  }
};

export const setupTokenRefreshInterval = () => {
  // Check token every 5 minutes
  const intervalId = setInterval(async () => {
    const authToken = localStorage.getItem("authToken");
    if (authToken && isTokenExpired(authToken)) {
      try {
        await refreshAuthToken();
      } catch (error) {
        console.error("Automatic token refresh failed:", error);
        clearInterval(intervalId);
      }
    }
  }, 5 * 60 * 1000);

  return intervalId;
};
