import { defineStore } from "pinia";
import axios from "axios";
import { API_URL } from "../config";

export const useRecordStore = defineStore("recordStore", {
  state: () => ({
    conferences: [],
    payments: [],
  }),
  actions: {
    // Fetch conferences from the API
    async fetchConferences() {
      try {
        const response = await axios.get(`${API_URL}/api/by_date`);
        // Filter to get only conference records
        this.conferences = response.data.records.filter(
          (record) => record.type === "conference"
        );
      } catch (error) {
        console.error("Error fetching conferences:", error);
      }
    },
    // Fetch payments from the API
    async fetchPayments() {
      try {
        const response = await axios.get(
          `${API_URL}/api/payments/sortedByDate`
        );
        this.payments = response.data.records;
      } catch (error) {
        console.error("Error fetching payments:", error);
      }
    },

    // Remove a conference record by id (updated to call backend API)
    async deleteConference(id) {
      try {
        // Call the backend API to delete the record
        await axios.delete(`${API_URL}/api/${id}`);

        await this.fetchConferences();

        // Update local state after successful deletion
        this.conferences = this.conferences.filter((conf) => conf.id !== id);
      } catch (error) {
        console.error("Error deleting conference in store:", error);
        throw error; // rethrow to allow the component to handle it
      }
    },

    // Add a new conference record
    addConference(newConference) {
      this.conferences.unshift(newConference);
      this.fetchConferences();
    },

    // Add a new payment record
    addPayment(newPayment) {
      this.payments.unshift(newPayment);
    },
    // Remove a payment record by id
    deletePayment(id) {
      this.payments = this.payments.filter((pay) => pay.id !== id);
    },
  },
});
